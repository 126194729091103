// 404.js
import React, { useState, useEffect } from 'react';
import Link from 'next/link'
import Head from 'next/head';
import Header from '../components/common/header';
import Footer from '../components/common/footer';
import { Container, Row, Col   } from 'react-bootstrap';
// Custom NPM Package
import { BackToTopButton,CookieBanner } from '@benchvondaranch/npm.react-ui-library';
//i18n
import { useTranslation } from 'react-i18next';
// Using Global Redux State
import { useSelector, useDispatch } from 'react-redux';
import { updateLocalStorage } from '../store/userSlice';

export default function FourOhFour() {
  const [isLoading, setIsLoading] = useState(true);//for test
  const { t, i18n } = useTranslation();
  const [settingsData, setSettingsData] = useState({});  
  const [showCookieBanner, setShowCookieBanner] = useState(true);
  const dispatch = useDispatch()

  const getSettingsData = async () => {        
    try {
          const response = await fetch(`/api/backend/settings?locale=${i18n.language}`, {
          headers: {
            Accept: "application/json",
          },
    });    
    const settingsDataFromBackend = await response.json();
    setSettingsData(settingsDataFromBackend);

    } catch (error) {
        console.log('[Error - loading Settings] - ' + error);
    }
    finally{
        setIsLoading(false)
    }
  };

 

  useEffect(() => {       
      async function fetchDataFromNextAPI() {   
          getSettingsData();                 
        }      
        fetchDataFromNextAPI();
        // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  // Cookie Banner START
  // Check if the user already agreed to the GDPCR - Cookie Banner
  useEffect(() => {
      if (typeof window !== 'undefined') {
       const cookieBannerDataFromLocalStorage = localStorage.getItem('cookieBannerData') ? JSON.parse(localStorage.getItem('cookieBannerData')) : undefined;
  
       if(cookieBannerDataFromLocalStorage?.accepted==="true")
       {
        setShowCookieBanner(false); 
       }
       else
       {
        setShowCookieBanner(true); 
       }
      } 
    },[])

    const selectAll = (e) => {
      const cookieData = {
        "necessary":"true",
        "marketing":"true",
        "performance":"true",
        "accepted":"true",
        "acceptedDateTime":`${new Date()}`,
        "savedViaButtonAction":`${e.target.getAttribute('data-c-action')}`,      
      }
      localStorage.setItem('cookieBannerData', JSON.stringify(cookieData));      
      dispatch(updateLocalStorage(cookieData))
      setShowCookieBanner(false);       
    }
    const saveSelection = (e) => {
      // Get All Checkboxes of CookieBanner
      const cbNecessary = document.getElementById('necessary').checked;
      const cbMarketing = document.getElementById('marketing').checked;
      const cbPerformance = document.getElementById('performance').checked;
      const cookieData = {
        "necessary": `${cbNecessary}`,
        "marketing":`${cbMarketing}`,
        "performance":`${cbPerformance}`,
        "accepted":"true",
        "acceptedDateTime":`${new Date()}`,
        "savedViaButtonAction":`${e.target.getAttribute('data-c-action')}`,      
      }
      localStorage.setItem('cookieBannerData', JSON.stringify(cookieData));
      dispatch(updateLocalStorage(cookieData))
      setShowCookieBanner(false);
    }
  // Cookie Banner END  

  return <>
        <Head>
        <title>Create Next App</title>
        <meta name="description" content="Generated by create next app" />
        <link rel="icon" href="/favicon.ico" />
      </Head>


      <Header  />
      <Container style={{
        paddingTop:'150px',
        paddingBottom:'150px',
      }}>
        <Row className='justify-content-center'>
        <Col></Col>
        <Col xs={'auto'} sm={'auto'} md={'auto'}  lg={'auto'}  xl={'auto'}>
        <h1>404 - Page Not Found</h1>
        <Link href="/">
          <a>
            Go back home
          </a>
        </Link>
        </Col>
        <Col></Col>

      </Row>
       
      </Container>
    <Footer settings={settingsData.Footer} />

    <CookieBanner
      title={ t("cookie_banner_title") }
      subtitle={ t("cookie_banner_subtitle") }
      description={ t("cookie_banner_description") }
      text_save_selection={ t("cookie_banner_text_save_selection") }
      text_accept_all={ t("cookie_banner_text_accept_all") }
      text_checkbox_option1={ t("cookie_banner_text_checkbox_option1") }
      text_checkbox_option2={ t("cookie_banner_text_checkbox_option2") }
      text_checkbox_option3={ t("cookie_banner_text_checkbox_option3") }
      gdpr_link={ t("cookie_banner_gdpr_link") }
      gdpr_link_text={ t("cookie_banner_gdpr_link_text") }
      imprint_link={ t("cookie_banner_imprint_link") }
      imprint_link_text={ t("cookie_banner_imprint_link_text") }
      display={showCookieBanner}
      handleAcceptAllClick={selectAll}
      handleSaveSelectionClick={saveSelection}
      ></CookieBanner>
  </>
}